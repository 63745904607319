.row {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
}
.row-reverse {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  flex-flow: row-reverse wrap;
}
.row-nowrap {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-flow: row nowrap;
}
.row-reverse-nowrap {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  flex-flow: row-reverse nowrap;
}
.column {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-flow: column nowrap;
}
.column-reverse {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  flex-flow: column-reverse nowrap;
}
.flex {
  flex: 1 1 auto;
}
.flex-self {
  flex: 0 1 auto;
}
.flex-shrink {
  flex: 1 1 0;
}
.flex-center {
  flex: 0 1 auto;
  margin: auto;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.justify-start {
  text-align: left;
  justify-content: flex-start;
}
.justify-center {
  text-align: center;
  justify-content: center;
}
.justify-end {
  text-align: right;
  justify-content: flex-end;
}
.justify-space-around {
  text-align: justify;
  justify-content: space-around;
}
.justify-space-between {
  text-align: justify;
  justify-content: space-between;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.bold {
  font-weight: bold;
}
.boldest {
  font-weight: 900;
}
.smallFont {
  font-family: SanFrancisocTextLight, sans-serif;
  font-size: 0.825rem;
  line-height: 1.2rem;
  font-weight: 100;
}
.mediumFont {
  font-family: SanFrancisocTextLight, sans-serif;
  font-size: 1.375rem;
  line-height: 2rem;
  font-weight: 100;
}
.largeFont {
  font-family: SanFrancisocTextLight, sans-serif;
  font-size: 1.65rem;
  line-height: 2.4rem;
  font-weight: 100;
}
.fixed {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
.hidden {
  display: none!important;
}
.invisible {
  opacity: 0!important;
}
html {
  font-size: 1.1rem;
  font-family: Avenir;
}
blinkid-in-browser {
  max-width: 400px;
  /* Customization of UI - see variables in src/components/shared/styles/_globals.scss */
  /*
    --mb-component-background: red;
    --mb-component-action-label: none;
    --mb-component-border-width: 0;
    --mb-component-action-buttons-width: 100%;
    --mb-component-action-buttons-justify-content: space-evenly;
    --mb-component-button-size: 48px;
    --mb-component-button-border-radius: 24px;
    */
}
.txtCenter {
  text-align: center;
}
body {
  margin: 0;
  padding: 0;
  cursor: default;
  min-height: 100vh;
}
* {
  box-sizing: border-box;
}
a {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:active {
  color: inherit;
  outline: 0;
}
.App {
  font-family: Avenir;
  -webkit-overflow-scrolling: touch;
}
.show {
  overflow: hidden;
}
img {
  border: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
@media (max-width: 767px) {
  body {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
.Toastify__progress-bar {
  background-color: #2b69a3;
  --toastify-color-progress-light: white;
}
.Toastify__progress-bar-theme--light {
  background: none;
}
.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  z-index: 1000;
}
.image-overlay-container {
  max-width: 80%;
  max-height: 80%;
  overflow: scroll;
  margin: auto;
}
.image-overlay-img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.doc-filter-select {
  width: 100%;
  padding: 8px 0;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}
*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.overlay-image-view {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
}
.rbc-time-view {
  background-color: white;
}
.multi-range-slider .thumb::before {
  box-shadow: none;
  border: none;
  background-color: #2089d5;
  position: absolute;
  top: 3px;
}
.multi-range-slider .bar-right {
  border: 1px solid #2089d5;
  box-shadow: none;
  background-color: white;
}
.multi-range-slider .bar-left {
  border: 1px solid #2089d5;
  box-shadow: none;
  background-color: white;
}
.multi-range-slider .thumb .caption * {
  background-color: inherit;
  box-shadow: none;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 0.65rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #2E6EA6;
}
.multi-range-slider .thumb .caption ::before {
  content: "$";
}
.multi-range-slider .thumb .caption ::after {
  content: "/hr";
  font-size: 0.5rem;
  position: relative;
  top: 2px;
}
.multi-range-slider .bar-inner {
  box-shadow: none;
  border: none;
}
.multi-range-slider .caption {
  display: flex;
}
.react-time-picker {
  width: 100%;
}
.react-time-picker__wrapper {
  border: none;
}
.react-time-picker__inputGroup__input {
  font-size: 24px;
  line-height: 29px;
}
.react-time-picker__inputGroup__amPm {
  width: 60px !important;
}
.react-time-picker__inputGroup__leadingZero {
  font-size: 24px;
  line-height: 29px;
  width: 35px !important;
}
.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.15em);
}
.privacy-policy body {
  font-family: 'Times New Roman';
  font-size: 12pt;
}
.privacy-policy h1,
.privacy-policy h2,
.privacy-policy h3,
.privacy-policy h4,
.privacy-policy h5,
.privacy-policy h6,
.privacy-policy p {
  margin: 0pt;
}
.privacy-policy li {
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.privacy-policy h1 {
  margin-left: 18pt;
  margin-bottom: 12pt;
  text-indent: -18pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  color: #000000;
}
.privacy-policy h2 {
  margin-left: 67.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  color: #000000;
}
.privacy-policy h3 {
  margin-left: 103.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  color: #000000;
}
.privacy-policy h4 {
  margin-left: 139.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  font-style: normal;
  color: #000000;
}
.privacy-policy h5 {
  margin-left: 175.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  color: #000000;
}
.privacy-policy h6 {
  margin-left: 211.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  color: #000000;
}
.privacy-policy .Heading7 {
  margin-left: 247.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  font-style: normal;
  color: #000000;
}
.privacy-policy .Heading8 {
  margin-left: 283.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  color: #000000;
}
.privacy-policy .Heading9 {
  margin-left: 319.5pt;
  margin-bottom: 12pt;
  text-indent: -36pt;
  text-align: justify;
  page-break-inside: auto;
  page-break-after: auto;
  font-family: 'Times New Roman';
  font-size: 12pt;
  font-weight: normal;
  font-style: normal;
  color: #000000;
}
.privacy-policy .BalloonText {
  font-family: 'Segoe UI';
  font-size: 9pt;
}
.privacy-policy .BlockText {
  margin-right: 36pt;
  margin-left: 36pt;
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .BlockText1 {
  margin-right: 72pt;
  margin-left: 72pt;
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .BodyText {
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .BodyText2 {
  text-align: justify;
  line-height: 200%;
  font-size: 12pt;
}
.privacy-policy .BodyTextFirstIndent {
  margin-bottom: 12pt;
  text-indent: 36pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .BodyTextFirstIndent2 {
  text-indent: 36pt;
  text-align: justify;
  line-height: 200%;
  font-size: 12pt;
}
.privacy-policy .CommentSubject {
  font-size: 10pt;
  font-weight: bold;
}
.privacy-policy .CommentText {
  font-size: 10pt;
}
.privacy-policy .DocID {
  font-size: 8pt;
  background-color: #ffffff;
}
.privacy-policy .DocumentMap {
  font-family: Tahoma;
  font-size: 12pt;
  background-color: #000080;
}
.privacy-policy .EndnoteText {
  font-size: 12pt;
}
.privacy-policy .EnvelopeAddress {
  margin-left: 144pt;
  font-size: 12pt;
}
.privacy-policy .EnvelopeReturn {
  font-size: 12pt;
}
.privacy-policy .Footer {
  font-size: 12pt;
}
.privacy-policy .FootnoteText {
  margin-bottom: 6pt;
  text-indent: 36pt;
  text-align: justify;
  line-height: 12pt;
  font-size: 12pt;
}
.privacy-policy .Header {
  font-size: 12pt;
}
.privacy-policy .Index1 {
  margin-left: 12pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index2 {
  margin-left: 24pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index3 {
  margin-left: 36pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index4 {
  margin-left: 48pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index5 {
  margin-left: 60pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index6 {
  margin-left: 72pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index7 {
  margin-left: 84pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index8 {
  margin-left: 96pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .Index9 {
  margin-left: 108pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .IndexHeading {
  font-family: Arial;
  font-size: 12pt;
  font-weight: bold;
}
.privacy-policy .LeftIndent5 {
  margin-left: 36pt;
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .LeftIndent1 {
  margin-left: 72pt;
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .LeftIndent15 {
  margin-left: 108pt;
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .LeftIndent2 {
  margin-left: 144pt;
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .LeftIndent25 {
  margin-left: 180pt;
  margin-bottom: 12pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .ListBullet {
  margin-left: 18pt;
  margin-bottom: 12pt;
  text-indent: -18pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .ListNumber {
  margin-left: 18pt;
  margin-bottom: 12pt;
  text-indent: -18pt;
  text-align: justify;
  font-size: 12pt;
}
.privacy-policy .ListNumber2 {
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .ListNumber3 {
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .ListNumber4 {
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .ListNumber5 {
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .ListParagraph {
  margin-left: 36pt;
  font-size: 12pt;
}
.privacy-policy .Macro {
  font-family: 'Courier New';
}
.privacy-policy .NoSpacing {
  font-size: 12pt;
}
.privacy-policy .NormalWeb {
  font-size: 12pt;
}
.privacy-policy .Revision {
  font-size: 12pt;
}
.privacy-policy .SignatureLeft {
  font-size: 12pt;
}
.privacy-policy .SignatureLeftRight {
  font-size: 12pt;
}
.privacy-policy .SignatureRight {
  margin-left: 216pt;
  font-size: 12pt;
}
.privacy-policy .Subtitle {
  margin-bottom: 3pt;
  text-align: center;
  font-size: 12pt;
}
.privacy-policy .TOAHeading {
  margin-top: 6pt;
  font-family: Arial;
  font-size: 12pt;
  font-weight: bold;
}
.privacy-policy .TOC1 {
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TOC2 {
  margin-left: 12.25pt;
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TOC3 {
  margin-left: 23.75pt;
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TOC4 {
  margin-left: 36pt;
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TOC5 {
  margin-left: 48.25pt;
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TOC6 {
  margin-left: 60pt;
  font-size: 12pt;
}
.privacy-policy .TOC7 {
  margin-left: 72pt;
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TOC8 {
  margin-left: 84.25pt;
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TOC9 {
  margin-left: 95.75pt;
  margin-bottom: 12pt;
  font-size: 12pt;
}
.privacy-policy .TableText {
  font-size: 12pt;
}
.privacy-policy .TableofAuthorities {
  margin-left: 12pt;
  text-indent: -12pt;
  font-size: 12pt;
}
.privacy-policy .TableofFigures {
  margin-left: 24pt;
  text-indent: -24pt;
  font-size: 12pt;
}
.privacy-policy .Title {
  margin-bottom: 12pt;
  text-align: center;
  page-break-after: avoid;
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
}
.privacy-policy .Title2 {
  margin-bottom: 12pt;
  text-align: center;
  page-break-after: avoid;
  font-size: 12pt;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}
.privacy-policy .Title3 {
  margin-bottom: 12pt;
  text-align: center;
  page-break-after: avoid;
  font-size: 12pt;
  font-weight: bold;
}
.privacy-policy .Title4 {
  margin-bottom: 12pt;
  page-break-after: avoid;
  font-size: 12pt;
  font-weight: bold;
}
.privacy-policy .Title5 {
  margin-bottom: 12pt;
  page-break-after: avoid;
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
}
.privacy-policy .UnnumberedHeading1 {
  text-align: center;
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
}
.privacy-policy .UnnumberedHeading2 {
  text-align: center;
  font-size: 12pt;
  font-weight: bold;
  font-style: italic;
}
.privacy-policy .UnnumberedHeading3 {
  text-align: center;
  font-size: 12pt;
  font-weight: bold;
}
.privacy-policy .UnnumberedHeading4 {
  text-align: center;
  font-size: 12pt;
  font-style: italic;
}
.privacy-policy .UnnumberedHeading5 {
  text-align: center;
  font-size: 12pt;
}
.privacy-policy span.BalloonTextChar {
  font-family: 'Segoe UI';
  font-size: 9pt;
}
.privacy-policy span.CommentReference {
  font-size: 8pt;
}
.privacy-policy span.DocIDChar {
  font-size: 8pt;
  background-color: #ffffff;
}
.privacy-policy span.EndnoteReference {
  vertical-align: super;
}
.privacy-policy span.FollowedHyperlink {
  text-decoration: underline;
  color: #800080;
}
.privacy-policy span.FootnoteReference {
  vertical-align: super;
}
.privacy-policy span.FootnoteTextChar {
  font-size: 12pt;
}
.privacy-policy span.Heading1Char {
  font-size: 12pt;
}
.privacy-policy span.Heading2Char {
  font-size: 12pt;
}
.privacy-policy span.Hyperlink {
  text-decoration: underline;
  color: #0000ff;
}
.privacy-policy span.ListParagraphChar {
  font-size: 12pt;
}
.privacy-policy span.UnresolvedMention {
  color: #605e5c;
  background-color: #e1dfdd;
}
@media (max-width: 900px) {
  .privacy-policy img {
    max-width: 100%;
    height: auto;
  }
  .privacy-policy .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .privacy-policy table {
    width: 100%;
    border-collapse: collapse;
  }
  .privacy-policy td,
  .privacy-policy th {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
}
